import { Flex, HStack, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface CartSummaryItemProps {
  label: string
  value?: string
  discountedValue?: string
  textStyle?: string
  children?: ReactNode
  isDiscount?: boolean
  fontStyle?: string
  fontWeight?: string
  infoBox?: ReactNode
  addon?: ReactNode
}

export const CartSummaryItem = (props: CartSummaryItemProps) => {
  const {
    label,
    value,
    discountedValue,
    textStyle,
    children,
    isDiscount,
    fontStyle,
    fontWeight,
    infoBox,
    addon,
  } = props

  return (
    <Flex
      aria-live="polite"
      aria-atomic="true"
      justifyContent="space-between"
      fontSize="md"
    >
      <HStack spacing="2">
        <Text
          as="span"
          textStyle={textStyle}
          fontWeight={fontWeight ? fontWeight : undefined}
        >
          {label}
        </Text>
        {infoBox && <Flex transform="translateY(1px)">{infoBox}</Flex>}
      </HStack>
      {addon && addon}
      {value ? (
        <Text textStyle={textStyle} color={isDiscount ? 'red' : undefined}>
          {isDiscount && '-'}
          <Text
            as={discountedValue ? 's' : 'span'}
            fontStyle={fontStyle ? fontStyle : undefined}
            color={discountedValue ? 'gray.500' : undefined}
            fontWeight={fontWeight ? fontWeight : undefined}
          >
            {value}
          </Text>
          {discountedValue && (
            <Text
              as="span"
              fontStyle={fontStyle ? fontStyle : undefined}
              ml="3"
              fontWeight={fontWeight ? fontWeight : undefined}
            >
              {discountedValue}
            </Text>
          )}
        </Text>
      ) : (
        children
      )}
    </Flex>
  )
}
