import {
  Box,
  Collapse,
  Divider,
  Flex,
  List,
  ListItem,
  Skeleton,
  Stack,
  StackProps,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  CartPromotionAccordion,
  IMAGE_PLACEHOLDER,
  Link,
  SPOONFLOWER_BASE_URL,
} from '@modules/app'
import useUserPreferences from '@modules/app/hooks/useUserPreferences'
import { CartPromotion } from '@modules/cart'
import {
  CartFragment,
  getCartDiscounts,
  getCartItemPrices,
  getCartTotals,
  useCart,
} from '@modules/commercetools'
import { useComposable } from '@modules/oriuminc/base'
import { HorizontalProductCard2 } from '@oriuminc/ui'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { FormatNumberOptions, useIntl } from 'react-intl'
import ActionButton from './components/ActionButton'
import { AfterPay } from './components/AfterPay'
import { CartSummaryItem } from './components/CartSummaryItem'
import { CouponCodeText } from './components/CouponCodeText'
import { IdMe } from './components/IdMe'
import PaymentMethods from './components/PaymentMethods'
import ShippingCartSummaryItem from './components/ShippingCartSummaryItem'
import { NoChargeMessage } from './components/NoChargeMessage'
import { exemptableCountries, countriesWithDuty } from './constant'
import TaxCartSummaryItem from './components/TaxCartSummaryItem'

export interface CartSummaryProps {
  checkoutStep?: string
  hideCheckoutButton?: boolean
  displayTitle?: boolean
  rootProps?: StackProps
  orderSummaryProps?: StackProps
  buttonProps: {
    buttonText: string
    onSubmit: () => void
    isDisabled: boolean
    isLoading: boolean
  }
  showCartItems?: boolean
  showCouponCode?: boolean
  showPaymentMethods?: boolean
  showSavings?: boolean
  showShipping?: boolean
  showTaxes?: boolean
  showTerms?: boolean
  textOverride?: {
    total: string
  }
  setIsUpdatingCoupon: React.Dispatch<React.SetStateAction<boolean>>
  isUpdatingCoupon: boolean
  isCartPage?: boolean
  isCouponEditable?: boolean
  spoonDollars?: {
    amountApplied: number
    updatedTotal: number
  }
}

export const CartSummary = ({
  checkoutStep,
  hideCheckoutButton,
  rootProps,
  orderSummaryProps,
  buttonProps,
  showCartItems = false,
  showCouponCode = true,
  showPaymentMethods = false,
  showSavings = true,
  showShipping = true,
  showTaxes = true,
  showTerms = false,
  textOverride,
  setIsUpdatingCoupon,
  isUpdatingCoupon,
  isCartPage = false,
  isCouponEditable = true,
  spoonDollars,
}: CartSummaryProps) => {
  const router = useRouter()
  const intl = useIntl()
  const {
    preferences: { country: preferredCountry, currency: preferredCurrency },
  } = useUserPreferences()

  const { cart } = useCart()
  const customer = cart?.customer
  const { buttonText, onSubmit, isDisabled, isLoading } = buttonProps

  const displayText = {
    total: textOverride?.total ?? 'cart.summary.total',
  }
  const { currency, path } = useComposable()

  const { subtotal, discount, total } = getCartTotals(cart as CartFragment)

  const { lineItemDiscounts } = getCartDiscounts(cart as CartFragment)

  const [isMobile] = useMediaQuery('(max-width: 480px)')

  const showFloatingAction =
    isMobile &&
    (isCartPage || checkoutStep === 'step1' || checkoutStep === 'step2')

  const showZeroDollarMessage = total === 0 && checkoutStep === 'step2'

  const formatNumberOptions: FormatNumberOptions = {
    currency: preferredCurrency ?? currency,
    style: 'currency',
  }

  const shouldShowSavingsSection = showSavings && lineItemDiscounts?.length > 0

  const showExemption = Boolean(
    checkoutStep === 'step1' &&
      customer &&
      exemptableCountries.includes(cart.billingAddress?.country)
  )

  useEffect(() => {
    addEventListener('scroll', () => {
      const floatingAction = document.getElementById('floating-action')

      if (floatingAction) {
        const y = window.scrollY
        const height = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        )
        y > height - 1200
          ? (floatingAction.style.visibility = 'hidden')
          : (floatingAction.style.visibility = 'visible')
      }
    })
  }, [])

  return (
    <Flex
      {...rootProps}
      flexDirection="column"
      width="full"
      bg="teal.50"
      borderTopLeftRadius={4}
      borderTopRightRadius={4}
      alignItems="center"
    >
      <Box
        paddingTop={{ base: 6, md: 12 }}
        paddingInline={{ base: 4, sm: 6, md: 12 }}
        {...orderSummaryProps}
        width="100%"
        maxWidth="488px"
      >
        {showCartItems && (
          <Stack>
            {cart.lineItems?.map((item) => {
              const brand = item.variant?.attributesRaw.find(
                (attr) => attr.name === 'brand'
              )?.value
              const { netPrice, netSalePrice } = getCartItemPrices(item)
              return (
                <HorizontalProductCard2
                  key={item.id}
                  columns={2}
                  size="sm"
                  image={{
                    src: item.variant?.images[0]?.url || IMAGE_PLACEHOLDER,
                    alt: item.name ?? '',
                    onClickImage: () =>
                      router.push(path.getPDP({ slug: item.productSlug! })),
                  }}
                  name={item.name || ''}
                  brand={brand}
                  quantity={item.quantity}
                  regularPrice={intl.formatNumber(
                    netPrice / 100,
                    formatNumberOptions
                  )}
                  salePrice={
                    netSalePrice
                      ? intl.formatNumber(
                          netSalePrice / 100,
                          formatNumberOptions
                        )
                      : undefined
                  }
                />
              )
            })}
          </Stack>
        )}
        <Stack spacing={3}>
          <CartSummaryItem
            label={`${intl.formatMessage({ id: 'cart.summary.subtotal' })}:`}
            value={intl.formatNumber(subtotal / 100, formatNumberOptions)}
          />

          <Collapse in={shouldShowSavingsSection} animateOpacity={false}>
            <VStack
              alignItems="flex-start"
              spacing={2}
              aria-live="polite"
              aria-atomic="true"
            >
              <CartSummaryItem
                label={`${intl.formatMessage({ id: 'cart.summary.savings' })}:`}
              />

              {/* Discounts */}
              <List spacing="2" width="full">
                {lineItemDiscounts?.map((discount, idx) => (
                  <ListItem key={idx}>
                    <CouponCodeText
                      couponCodeName={discount.name}
                      discount={discount.value}
                      hideDiscount={discount.hide}
                      formatNumberOptions={formatNumberOptions}
                    />
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Collapse>

          {showShipping && (
            <ShippingCartSummaryItem currency={preferredCurrency ?? currency} />
          )}

          {showTaxes && <TaxCartSummaryItem checkoutStep={checkoutStep} />}
        </Stack>

        {/* Coupon Code */}
        {showCouponCode && isCouponEditable && (
          <Box marginTop={3}>
            <CartPromotionAccordion isOpen={false} discount={discount}>
              <CartPromotion setIsUpdatingCoupon={setIsUpdatingCoupon} />
            </CartPromotionAccordion>
          </Box>
        )}

        <Box marginBottom={spoonDollars ? 3 : 4} marginTop={3}>
          <Skeleton isLoaded={!isUpdatingCoupon} borderRadius={5}>
            <CartSummaryItem
              label={intl.formatMessage({ id: displayText.total })}
              value={
                cart.totalPrice
                  ? intl.formatNumber(total / 100, formatNumberOptions)
                  : undefined
              }
              fontWeight={spoonDollars ? undefined : 'bold'}
            />
          </Skeleton>
        </Box>

        {showZeroDollarMessage && <NoChargeMessage />}

        {spoonDollars && (
          <Stack spacing={3} marginBottom={4}>
            <CouponCodeText
              couponCodeName={intl.formatMessage({
                id: 'cart.summary.spoondollarsApplied',
              })}
              discount={spoonDollars.amountApplied}
              formatNumberOptions={formatNumberOptions}
            />

            <CartSummaryItem
              fontWeight="bold"
              label={intl.formatMessage({ id: 'cart.summary.youPay' })}
              value={intl.formatNumber(
                spoonDollars.updatedTotal,
                formatNumberOptions
              )}
            />
          </Stack>
        )}

        {showTerms && (
          <Text as="p" size="xs" variant="p">
            {intl.formatMessage(
              { id: 'cart.summary.byPlacingYourOrder' },
              {
                termsOfServiceLink: (
                  <Link href={`${SPOONFLOWER_BASE_URL}/terms`} target="_blank">
                    {intl.formatMessage({ id: 'cart.summary.termsOfService' })}
                  </Link>
                ),
                privacyNoticeLink: (
                  <Link
                    href={`${SPOONFLOWER_BASE_URL}/en/privacy`}
                    target="_blank"
                  >
                    {intl.formatMessage({ id: 'cart.summary.privacyNotice' })}
                  </Link>
                ),
              }
            )}
          </Text>
        )}

        {!hideCheckoutButton && (
          <ActionButton
            onClick={onSubmit}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {buttonText}
          </ActionButton>
        )}

        {!hideCheckoutButton && showFloatingAction && (
          <Box
            id="floating-action"
            paddingTop="0.75rem"
            paddingBottom="1rem"
            paddingX="1.5rem"
            backgroundColor="warm-white"
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            zIndex={1}
          >
            <ActionButton
              onClick={onSubmit}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {buttonText}{' '}
              {cart.totalPrice
                ? ' - ' + intl.formatNumber(total / 100, formatNumberOptions)
                : ''}
            </ActionButton>
          </Box>
        )}

        {showPaymentMethods && (
          <Box marginTop="4">
            <PaymentMethods />
          </Box>
        )}

        <Box marginBlock={4}>
          <AfterPay isCartSummary />
        </Box>

        {isCartPage && <Divider display={{ base: 'block', xl: 'none' }} />}
      </Box>
      {isCartPage && <IdMe />}
    </Flex>
  )
}
